import React from 'react'
import { IconsProps } from 'msp-integrations'

const ArrowRightStickIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 30.125 18.1738'
    >
      <path d="M29.875 8.483 21.653.262a.848.848 0 0 0-.6-.261L21.036 0a.853.853 0 0 0-.595.24.864.864 0 0 0-.003 1.23L27.2 8.232H.857a.857.857 0 1 0 0 1.713H27.2l-6.76 6.76a.858.858 0 0 0-.02 1.21.846.846 0 0 0 .61.259.895.895 0 0 0 .62-.256l8.224-8.224a.859.859 0 0 0 0-1.211Z" />
    </svg>
  )
}

export default ArrowRightStickIcon
